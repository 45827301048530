import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { AuthService } from '../shared/auth.service';
import { Subscription } from 'rxjs';
import { API_BASE_URL } from '../../clients/apiClients';

@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html'
})
export class AppFooterComponent implements OnInit, OnDestroy {
  layoutService = inject(LayoutService);
  private authService = inject(AuthService);
  apiUrl = inject(API_BASE_URL);

  token: string | null = null;
  tokenSubscription?: Subscription;
  isSuperAdmin: boolean = false;

  ngOnDestroy() {
    this.tokenSubscription?.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    this.tokenSubscription = this.authService.token.subscribe(
      (token) => (this.token = token)
    );
    const permissions = await this.authService.permissions();
    this.isSuperAdmin = permissions.includes('AccessAll');
  }
}
