import { Component, OnInit, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserProfileResult } from '../../clients/apiClients';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {
  private store = inject(Store);

  public profile$?: Observable<UserProfileResult>;

  constructor() {
    const titleService = inject(Title);

    titleService.setTitle('Profile - cybersort');
  }

  ngOnInit() {
    this.getProfile();
  }

  getProfile() {
    this.profile$ = this.store.select((o) => o.app.profile);
  }
}
