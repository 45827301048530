import { NgModule, inject } from '@angular/core';
import { NavigationEnd, Router, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { AssetComponent } from './asset/asset.component';
import { AppLayoutComponent } from './layout/app.layout.component';
import { LoginComponent } from './login/login.component';
import { ImportAssetsComponent } from './import/import-assets/import-assets.component';
import { AuthGuard } from './shared/auth-guard.service';
import { ImportApplicationRequirementsComponent } from './import/import-application-requirements/import-application-requirements.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import posthog from 'posthog-js';
import { SearchComponent } from './search/search.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'asset-function-type',
        canLoad: [AuthGuard],
        data: {
          permission: 'MasterData'
        },
        loadChildren: () =>
          import('./asset-function-type/asset-function-type.module').then(
            (m) => m.AssetFunctionTypeModule
          )
      },
      {
        path: 'application-requirement-type',
        canLoad: [AuthGuard],
        data: {
          permission: 'MasterData'
        },
        loadChildren: () =>
          import(
            './application-requirement-type/application-requirement-type.module'
          ).then((m) => m.ApplicationRequirementTypeModule)
      },
      {
        path: 'application-implementation-type',
        canLoad: [AuthGuard],
        data: {
          permission: 'MasterData'
        },
        loadChildren: () =>
          import(
            './application-implementation-type/application-implementation-type.module'
          ).then((m) => m.ApplicationImplementationTypeModule)
      },
      {
        path: 'computing-platform',
        canLoad: [AuthGuard],
        data: {
          permission: 'MasterData'
        },
        loadChildren: () =>
          import('./computing-platform/computing-platform.module').then(
            (m) => m.ComputingPlatformModule
          )
      },
      {
        path: 'admin',
        canLoad: [AuthGuard],
        data: {
          permission: 'AccessAll'
        },
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule)
      },
      {
        path: 'management',
        canLoad: [AuthGuard],
        data: {
          permission: 'Users'
        },
        loadChildren: () =>
          import('./management/management.module').then(
            (m) => m.ManagementModule
          )
      },
      {
        path: 'asset',
        canLoad: [AuthGuard],
        component: AssetComponent,
        loadChildren: () =>
          import('./asset/asset.module').then((m) => m.AssetModule)
      },
      {
        path: 'search',
        canLoad: [AuthGuard],
        component: SearchComponent,
        loadChildren: () =>
          import('./search/search.module').then((m) => m.SearchModule)
      },
      {
        path: 'import-assets',
        canLoad: [AuthGuard],
        data: {
          permission: 'ReadWrite'
        },
        component: ImportAssetsComponent
      },
      {
        path: 'import-application-requirements',
        canLoad: [AuthGuard],
        component: ImportApplicationRequirementsComponent,
        data: {
          permission: 'ReadWrite'
        }
      },
      {
        path: 'profile',
        component: ProfileComponent,
        data: {}
      }
    ]
  }
];

const isIframe = window !== window.parent && window.opener == undefined;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled', // Don't perform initial navigation in iframes,
      canceledNavigationResolution: 'computed'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  private router = inject(Router);

  constructor() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        posthog.capture('$pageview');
      }
    });
  }
}
