import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import { AuthenticateClient } from '../../clients/apiClients';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { defaultIfEmpty, distinct, map, Observable, Subscription } from 'rxjs';
import { AuthService } from '../shared/auth.service';
import { TenantService } from '../shared/tenant.service';
import posthog from 'posthog-js';
import { Tenant } from './tenant';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit, OnDestroy {
  layoutService = inject(LayoutService);
  authenticateClient = inject(AuthenticateClient);
  private authService = inject(AuthService);
  private tenantService = inject(TenantService);
  private router = inject(Router);
  private store = inject(Store);

  items$?: Observable<MenuItem[]>;
  public tenant$: Observable<Tenant | undefined>;
  private subscriptions = new Subscription();

  constructor() {
    const store = this.store;

    this.tenant$ = store
      .select((o) => o.app.profile?.tenantName)
      .pipe(
        defaultIfEmpty(
          this.tenantService.getLinkedTenant().pipe(map((n) => n?.name))
        )
      );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  logout() {
    this.authService.logout();
  }

  async ngOnInit(): Promise<void> {
    const permissions = await this.authService.permissions();
    if (permissions.includes('AccessAll')) {
      this.tenant$ = this.tenantService.getLinkedTenant().pipe(
        filter((n) => !!n),
        map((n) =>
          n?.name && n?.dataKey ? new Tenant(n.name, n.dataKey) : undefined
        )
      );
    } else {
      this.tenant$ = this.store
        .select((o) => o.app.profile)
        .pipe(
          filter((o) => !!o),
          distinct(),
          map((o) => new Tenant(o.tenantName, o.dataKey))
        );
    }

    const tenantSubscription = this.tenant$.subscribe((tenant) => {
      if (!tenant?.name) return;
      posthog.group('company', tenant.dataKey, { name: tenant.name });
    });

    this.subscriptions.add(tenantSubscription);

    const applicationImplementationTypeEnabled = posthog.isFeatureEnabled(
      'application_implementation_type'
    );

    this.items$ = this.tenant$.pipe(
      map((tenant) => {
        return [
          {
            label: 'Assets',
            icon: 'pi pi-fw pi-list',
            routerLink: ['asset'],
            visible: permissions.includes('ReadWrite') && !!tenant
          },
          {
            label: 'Import',
            icon: 'pi pi-fw pi-file-import',
            visible: permissions.includes('ReadWrite') && !!tenant,
            items: [
              {
                label: 'Assets',
                routerLink: ['import-assets']
              },
              {
                label: 'Application requirements',
                routerLink: ['import-application-requirements']
              }
            ]
          },
          {
            label: 'Search',
            icon: 'pi pi-fw pi-search',
            routerLink: ['search'],
            visible: permissions.includes('ReadWrite') && !!tenant
          },
          {
            label: 'Configuration',
            icon: 'pi pi-fw pi-cog',
            visible: permissions.includes('MasterData') && !!tenant,
            items: [
              {
                label: 'Asset function types',
                icon: 'pi pi-fw pi-database',
                routerLink: ['asset-function-type']
              },
              {
                label: 'Application requirement types',
                icon: 'pi pi-fw pi-database',
                routerLink: ['application-requirement-type']
              },
              {
                label: 'Application implementation types',
                icon: 'pi pi-fw pi-database',
                routerLink: ['application-implementation-type'],
                visible: applicationImplementationTypeEnabled
              },
              {
                label: 'Computing platforms',
                icon: 'pi pi-fw pi-server',
                routerLink: ['computing-platform']
              }
            ]
          },
          {
            label: 'Management',
            icon: 'pi pi-fw pi-cog',
            routerLink: ['management'],
            visible: permissions.includes('Users') && !!tenant
          },
          {
            label: 'Admin',
            icon: 'pi pi-fw pi-cog',
            routerLink: ['admin'],
            visible: permissions.includes('AccessAll')
          }
        ];
      })
    );
  }
}
