import { Injectable, inject } from '@angular/core';
import { AuthService } from './auth.service';
import {
  CreateTenantCommand,
  LinkedTenantResult,
  TenantClient
} from '../../clients/apiClients';
import { BehaviorSubject, switchMap } from 'rxjs';
import { Store } from '@ngxs/store';
import {
  ResetApplicationRequirementTypes,
  ResetFunctionTypes
} from '../../shared/master-data.actions';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  private authService = inject(AuthService);
  private tenantClient = inject(TenantClient);
  private store = inject(Store);

  tenantSubject: BehaviorSubject<LinkedTenantResult | null> =
    new BehaviorSubject<LinkedTenantResult | null>(null);

  constructor() {
    this.authService.permissions().then((perms) => {
      if (!perms.includes('AccessAll')) return;
      this.tenantClient.getLinkedTenant().subscribe((tenant) => {
        this.tenantSubject.next(tenant);
      });
    });
  }

  listTenants() {
    return this.tenantClient.listTenants();
  }

  create(name: string) {
    return this.tenantClient.create(
      CreateTenantCommand.fromJS({ tenantName: name })
    );
  }

  selectLinkedTenant(id: number) {
    this.tenantClient
      .startAccess(id)
      .pipe(switchMap(() => this.tenantClient.getLinkedTenant()))
      .subscribe((tenant) => {
        this.tenantSubject.next(tenant);
      });
    this.store.dispatch([
      new ResetFunctionTypes(),
      new ResetApplicationRequirementTypes()
    ]);
  }

  deselectLinkedTenant() {
    this.store.dispatch([
      new ResetFunctionTypes(),
      new ResetApplicationRequirementTypes()
    ]);
    this.tenantClient.stopAccess().subscribe(() => {
      this.tenantSubject.next(null);
    });
  }

  getLinkedTenant() {
    return this.tenantSubject.asObservable();
  }
}
