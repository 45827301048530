import { EventEmitter, Injectable, inject } from '@angular/core';
import {
  AssetClient,
  AssetCreateResult,
  AssetParentChangedContract,
  AssetRemoveResult,
  AssetResult,
  AssetRootCreatedContract,
  AssetUpdateResult,
  ChangeAssetParentContract,
  CreateAssetCommand,
  DuplicateAssetContract,
  FileParameter,
  LinkApplicationRequirementInterfaceCommand,
  UpdateApplicationRequirementInterfaceLink,
  UpdateAssetClient,
  UpdateAssetCommand
} from '../../clients/apiClients';
import {
  defer,
  finalize,
  firstValueFrom,
  Observable,
  Subject,
  takeUntil,
  tap
} from 'rxjs';
import { TreeNode } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class AssetService {
  private assetClient = inject(AssetClient);
  private updateAssetClient = inject(UpdateAssetClient);

  public rootAdded$: EventEmitter<AssetRootCreatedContract>;
  public assetAdded$: EventEmitter<AssetCreateResult>;
  public assetParentChanged$: EventEmitter<AssetParentChangedContract>;
  public assetRemoved$: EventEmitter<AssetRemoveResult>;
  public assetUpdated$: EventEmitter<AssetUpdateResult>;
  public nodePicked$: EventEmitter<TreeNode>;
  public nodeUnpicked$: EventEmitter<TreeNode>;
  public selectAsset$: EventEmitter<string>;
  public isMoving: boolean;
  public isDuplicating: boolean;

  constructor() {
    this.rootAdded$ = new EventEmitter<AssetRootCreatedContract>();
    this.assetAdded$ = new EventEmitter<AssetCreateResult>();
    this.assetRemoved$ = new EventEmitter<AssetRemoveResult>();
    this.assetUpdated$ = new EventEmitter<AssetUpdateResult>();
    this.assetParentChanged$ = new EventEmitter<AssetParentChangedContract>();
    this.nodePicked$ = new EventEmitter<TreeNode>();
    this.nodeUnpicked$ = new EventEmitter<TreeNode>();
    this.selectAsset$ = new EventEmitter<string>();
    this.isMoving = false;
    this.isDuplicating = false;
  }

  getAsset(
    id: string,
    cancel: Observable<void> | undefined = undefined
  ): Observable<AssetResult> {
    if (!cancel) cancel = new Subject();
    return this.assetClient.getAsset(id).pipe(takeUntil(cancel));
  }

  getAssetApplicationRequirement(assetId: string, id: number) {
    return this.assetClient.getApplicationRequirement(assetId, id);
  }

  getAssetApplicationRequirements(assetId: string) {
    return this.assetClient.getApplicationRequirements(assetId);
  }

  linkApplicationRequirementInterfaces(
    command: LinkApplicationRequirementInterfaceCommand
  ) {
    return this.assetClient.linkApplicationRequirementInterfaces(command);
  }

  removeApplicationRequirementInterfaceLink(id: number) {
    return this.assetClient.removeApplicationRequirementLink(id);
  }

  async addChild(parentId: string, command: CreateAssetCommand) {
    const result = await firstValueFrom(
      this.assetClient.addChild(parentId, command)
    );
    if (!result.hasDuplicateName) this.assetAdded$.emit(result);
    return result;
  }

  async addRoot(command: CreateAssetCommand) {
    const result = await firstValueFrom(this.assetClient.addRoot(command));
    this.rootAdded$.emit(result);
    return result;
  }

  selectNode(assetId: string) {
    this.selectAsset$.emit(assetId);
  }

  async remove(id: string) {
    const result = await firstValueFrom(this.assetClient.remove(id));
    this.assetRemoved$.emit(result);
  }

  update(contract: UpdateAssetCommand) {
    return this.updateAssetClient.update(contract).pipe(
      this.finalizeWithValue((result) => {
        if (!result.hasDuplicateName) this.assetUpdated$.emit(result);
      })
    );
  }

  finalizeWithValue<T>(callback: (value: T) => void) {
    return (source: Observable<T>) =>
      defer(() => {
        let lastValue: T;
        return source.pipe(
          tap((value) => (lastValue = value)),
          finalize(() => callback(lastValue))
        );
      });
  }

  list() {
    return this.assetClient.list();
  }

  getHierarchy() {
    return this.assetClient.getHierarchy();
  }

  getFullHierarchy() {
    return this.assetClient.getFullHierarchy();
  }

  async duplicate(
    parentId: string,
    idToCopy: string
  ): Promise<AssetCreateResult> {
    const result = await firstValueFrom(
      this.assetClient.duplicate(
        DuplicateAssetContract.fromJS({
          parentId: parentId,
          idToCopy: idToCopy
        })
      )
    );
    result.forEach((o) => this.assetAdded$.emit(o));
    return result[0];
  }

  async changeParent(assetId: string, targetId: string) {
    const result = await firstValueFrom(
      this.assetClient.changeParent(
        ChangeAssetParentContract.fromJS({
          assetId: assetId,
          targetId: targetId
        })
      )
    );
    this.assetParentChanged$.emit(result);
    return result;
  }

  async upload(id: string, files: File[]) {
    const fileParameters: FileParameter[] = files.map((file) =>
      this.mapFile(file)
    );

    return await firstValueFrom(
      this.assetClient.uploadFiles(id, fileParameters)
    );
  }

  mapFile(file: File) {
    return { data: file, fileName: file.name };
  }

  async download(attachmentId: number) {
    return await firstValueFrom(this.assetClient.downloadFile(attachmentId));
  }

  async removeAttachment(attachmentId: number) {
    return await firstValueFrom(
      this.assetClient.removeAttachment(attachmentId)
    );
  }

  async getInterfaceLinks(assetId: string) {
    const result = await firstValueFrom(
      this.assetClient.getInterfaceLinks(assetId)
    );
    return result;
  }
  async updateLink(id: number, description: string) {
    await firstValueFrom(
      this.assetClient.updateApplicationRequirementInterfaceLink(
        UpdateApplicationRequirementInterfaceLink.fromJS({
          id: id,
          description: description
        })
      )
    );
  }
}
