import { Injectable, inject } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { FetchUser, ResetUser } from './app.actions';
import { AuthenticateClient, UserProfileResult } from '../clients/apiClients';
import { firstValueFrom } from 'rxjs';

export class AppStateModel {
  profile?: UserProfileResult;
}

@State<AppStateModel>({
  name: 'app',
  defaults: {}
})
@Injectable()
export class AppState {
  private authenticationClient = inject(AuthenticateClient);

  @Action(FetchUser)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async fetchUser(ctx: StateContext<AppStateModel>, action: FetchUser) {
    const profile = await firstValueFrom(
      this.authenticationClient.getProfile()
    );

    ctx.patchState({ profile: profile });
  }

  @Action(ResetUser)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  resetUser(ctx: StateContext<AppStateModel>, action: ResetUser) {
    ctx.patchState({ profile: undefined });
  }
}
