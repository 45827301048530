<p-tree
  (onNodeSelect)="nodeSelect($event)"
  (onNodeUnselect)="nodeUnselect($event)"
  *ngIf="assetTree; else loading"
  [(selection)]="selection"
  [value]="assetTree"
  (onNodeCollapse)="nodeCollapse($event)"
  (onNodeExpand)="nodeExpand($event)"
  [styleClass]="isMoving || isDuplicating ? 'assetTree-highlight' : 'assetTree'"
  selectionMode="single"
  #tree
>
  <ng-template pTemplate="empty">
    <ng-container *ngIf="!isSuperAdmin">
      <p>
        No assets or permissions to assets yet. Ask you admin to add assets or
        permissions for you.
      </p>
    </ng-container>
    <ng-container *ngIf="isSuperAdmin">
      <button
        (click)="createRootAsset()"
        label="Create first asset"
        pButton
      ></button>
    </ng-container>
  </ng-template>
</p-tree>

<ng-template #loading>
  <div class="p-2">Loading...</div>
</ng-template>
