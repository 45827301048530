import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../shared/auth.service';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {
  AuthenticateResult,
  AuthenticationStatus
} from '../../clients/apiClients';
import { MessageService } from 'primeng/api';
import posthog from 'posthog-js';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  private authService = inject(AuthService);
  private fb = inject(FormBuilder);
  private router = inject(Router);
  private messageService = inject(MessageService);

  public errorMessage?: string;
  public otpNeeded = false;
  loginForm = this.fb.nonNullable.group({
    email: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required]
    }),
    password: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required]
    }),
    otpCode: new FormControl('', {
      nonNullable: true,
      validators: [this.optValidator(() => this.otpNeeded)]
    })
  });

  forgotPasswordForm = this.fb.nonNullable.group({
    email: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required]
    })
  });

  public forgotPassword = false;
  public isLoading = false;

  login() {
    this.errorMessage = undefined;

    const email = this.loginForm.value.email;
    const password = this.loginForm.value.password;
    const otpCode = this.loginForm.value.otpCode;

    if (!email || !password) return;
    const loginObserver = {
      next: async (result: AuthenticateResult) => {
        if (result.status == AuthenticationStatus.OtpRequired) {
          this.otpNeeded = true;
          this.isLoading = false;
          posthog.capture('otp_required', { email: result.email });
        } else if (result.status == AuthenticationStatus.Success) {
          this.errorMessage = undefined;
          posthog.identify(result.userId, { email: result.email });
          await this.router.navigate(['/']);
          this.otpNeeded = false;
          this.isLoading = false;
        }
      },
      error: (error: string) => {
        posthog.capture('failed_login', { email: email });
        this.errorMessage = error;
        this.isLoading = false;
      }
    };

    this.isLoading = true;
    this.authService.login(email, password, otpCode).subscribe(loginObserver);
  }

  optValidator(otpNeeded: () => boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!otpNeeded()) return null;

      if (
        !control.value ||
        control.value.length != 6 ||
        !/^\d+$/.test(control.value)
      )
        return { length: 'Code has to be 6 numbers' };

      return null;
    };
  }

  showForgotPassword() {
    this.forgotPassword = true;
  }

  sendForgotPassword() {
    const email = this.forgotPasswordForm.value.email;

    if (!email) throw Error('Email cannot be empty');

    this.authService.forgotPassword(email).subscribe(() => {
      this.forgotPassword = false;
      this.messageService.add({
        severity: 'success',
        detail: `If the user exists in the system, a recovery email is sent to ${email}`
      });
    });
  }
}
