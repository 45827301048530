import { Injectable } from '@angular/core';
import { HelpTextResult, HelpTextsClient } from '../../clients/apiClients';
import posthog from 'posthog-js';

@Injectable({
  providedIn: 'root'
})
export class HelpService {
  private helpTexts = new Map<string, HelpTextResult | undefined>();
  constructor(private helpTextsClient: HelpTextsClient) {}

  initialize(): Promise<void> {
    if (!posthog.isFeatureEnabled('help_texts')) {
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      this.helpTextsClient.getApiHelp().subscribe({
        next: (helpTexts) => {
          helpTexts.forEach((helpText: HelpTextResult) => {
            this.helpTexts.set(helpText.identifier, helpText);
          });
          resolve();
        },
        error: () => {
          resolve();
        }
      });
    });
  }

  public openHelpEditUrl(key: string): void {
    this.helpTextsClient.getApiHelpEdit(key).subscribe((url) => {
      window.open(url, '_blank');
    });
  }

  public getHelpText(key: string): HelpTextResult | undefined {
    return this.helpTexts.get(key);
  }

  public isEditing(): boolean {
    return sessionStorage.getItem('helpEditing') === 'true';
  }
}
