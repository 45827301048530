import { Component, OnInit, inject } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { LayoutService } from './layout/service/app.layout.service';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { ActivationStart, NavigationError, Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { APP_CONFIG } from './common/app-config';
import { map } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private primengConfig = inject(PrimeNGConfig);
  private layoutService = inject(LayoutService);
  private titleService = inject(Title);
  private router = inject(Router);

  title = 'cybersort.io';

  constructor() {
    const appConfig = inject(APP_CONFIG);

    const angularPlugin = new AngularPlugin();
    if (appConfig.applicationInsights) {
      const appInsights = new ApplicationInsights({
        config: {
          connectionString: appConfig.applicationInsights,
          extensions: [angularPlugin],
          enableCorsCorrelation: true,
          enableRequestHeaderTracking: true,
          enableResponseHeaderTracking: true,
          extensionConfig: {
            [angularPlugin.identifier]: { router: this.router }
          }
        }
      });
      appInsights.loadAppInsights();
    }
  }

  ngOnInit() {
    this.primengConfig.ripple = true; //enables core ripple functionality

    //optional configuration with the default configuration
    this.layoutService.config = {
      ripple: false, //toggles ripple on and off
      inputStyle: 'outlined', //default style for input elements
      menuMode: 'static', //layout mode of the menu, valid values are "static" and "overlay"
      colorScheme: 'light', //color scheme of the template, valid values are "light" and "dark"
      theme: 'lara-light-indigo', //default component theme for PrimeNG
      scale: 15 //size of the body font size to scale the whole application
    };

    this.router.events
      .pipe(filter((o) => o instanceof ActivationStart))
      .subscribe(() => {
        this.titleService.setTitle('cybersort');
      });

    this.router.events
      .pipe(
        filter((evt) => evt instanceof NavigationError),
        map((evt) => evt as NavigationError)
      )
      .subscribe((evt) => {
        if (evt.error instanceof Error && evt.error.name == 'ChunkLoadError') {
          window.location.assign(evt.url);
        }
      });
  }
}
