<div *ngIf="profile$ | async as profile; else loading">
  <ul class="list-none p-0 m-0 overflow-autos max-w-30rem" pFocusTrap>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Email</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        {{ profile.email }}
      </div>
    </li>
    <li
      class="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap"
    >
      <div class="text-500 w-6 md:w-2 font-medium">Tenant</div>
      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
        {{ profile.tenantName }}
      </div>
    </li>
  </ul>
</div>

<ng-template #loading>
  <div>Loading...</div>
</ng-template>
